<template>
  <div class="title">
    <h1 style="font-family: Roboto; font-size: 56px;">Raiyan Rahman</h1>
    <h1>Machine Learning Engineer | Computer Vision Research</h1>
  </div>
</template>

<script>
export default {
  name: 'TitleBar',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 10%;
}
h1 {
  text-align: center;
  color: #cccbc5;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
